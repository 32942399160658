import Test from "./pages/test";
import userProfile from "./pages/userProfile";
import InspectionPreContact from "./pages/InspectionPreContact";
import InactiveInspections from "./pages/InactiveInspections";
import MapTesting from "./pages/MapTesting";
import InspectorInvoicePrices from "./pages/InspectorInvoicePrices";
import SoftwareInvoicePrices from "./pages/SoftwareInvoicePrices";
import InspectionPrices from "./pages/InspectionPrices";
import Invoices from "./pages/Invoices";
import MissingPhotos from "./pages/MissingPhotos";
import InspectionSupplements from "./pages/InspectionSupplements";
import IncomingInspections from "./pages/IncomingInspections";
import SoftwareInvoices from "./pages/SoftwareInvoices";
import InspectorInvoices from "./pages/InspectorInvoices";
import MyBilling from "./pages/MyBilling";
import AreaMap from "./pages/AreaMap";
import AreaMapInspectors from "./pages/AreaMapInspectors";
import InspectionTime from "./pages/InspectionTime";
import LateReport from "./pages/LateReport";
import InspectionTimeCreated from "./pages/InspectionTimeCreated";
import LateReportList from "./pages/LateReportList";
import PolicyListStatus from "./pages/PolicyListStatus";
import Planner from "./pages/Planner";
import LateDash from "./pages/LateDash";
import OrchidOpenList from "./pages/OrchidOpenList";
import OpenMap from "./pages/OpenMap";

// Add root components to this list so that they can be loaded dynamically
const COMPONENTS = {
  Test,
  userProfile,
  InspectionPreContact,
  InactiveInspections,
  InspectionPrices,
  InspectorInvoicePrices,
  SoftwareInvoicePrices,
  Invoices,
  InspectionSupplements,
  MissingPhotos,
  IncomingInspections,
  SoftwareInvoices,
  InspectorInvoices,
  MyBilling,
  MapTesting,
  AreaMap,
  AreaMapInspectors,
  InspectionTime,
  LateReport,
  InspectionTimeCreated,
  LateReportList,
  PolicyListStatus,
  LateDash,
  Planner,
  OrchidOpenList,
  OpenMap
};

export default COMPONENTS;
