import { ApiCore } from "./core";
import { apiProvider } from "./core/provider";

const url = "inspections";

const apiInspections = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

apiInspections.deleteDocument = function(policyNo,documentID) {
  return apiProvider.delete(`/inspection/document/${policyNo}`,documentID);
};

apiInspections.getPlannedInspections = function(inspectorID, date, showInspectionsWithNoAppointments) {
  return apiProvider.get(`inspections/planned?inspectorID=${inspectorID}&date=${date}&noappointment=${showInspectionsWithNoAppointments}`);
};

apiInspections.getOpenInspectionsMap = function(types) {
  return apiProvider.list(`inspections/openmap?types=${types.join(",")}`);
};

export { apiInspections };
